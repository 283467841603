var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f-home-box"},[_c('div',{staticClass:"header-box"},[(_vm.scrollTopVal > 0)?_c('div',{staticStyle:{"width":"100%"}}):_vm._e(),_c('div',{class:['header', _vm.scrollTopVal > 0 ? 'scrollTopFixed' : '']},[_vm._m(0),_c('div',{staticClass:"header-left"},[_c('div',{staticClass:"menu-style"},[_c('a-menu',{attrs:{"mode":"horizontal"},model:{value:(_vm.currents),callback:function ($$v) {_vm.currents=$$v},expression:"currents"}},[_c('a-menu-item',{key:"topBox",staticClass:"menu-item-box",on:{"click":function($event){return _vm.navbar({name:'首页'})}}},[_vm._v("首页 "),_c('img',{staticClass:"menu-title-bg",attrs:{"src":require("../../assets/new/title-bg.png"),"alt":""}})]),_c('a-menu-item',{key:"aboutUs",on:{"click":function($event){return _vm.navbar({name:'关于我们'})}}},[_vm._v("关于我们")])],1)],1)])])]),_c('div',{ref:"topBox",staticClass:"topBox"},[_c('img',{staticClass:"topBox-bg",attrs:{"src":require("../../assets/new/index-bg.png"),"alt":""}}),_vm._m(1),_vm._m(2)]),_vm._m(3),_c('div',{staticClass:"about-box"},[_c('div',{ref:"aboutUs",staticClass:"aboutUs"},[_vm._m(4),_c('img',{staticClass:"about-us-img",attrs:{"src":require("../../assets/new/about-us.png"),"alt":""}})])]),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logoBox"},[_c('img',{staticClass:"logo-img",attrs:{"src":require("../../assets/new/logo.jpg"),"alt":""}}),_c('div',{staticClass:"logo-name"},[_vm._v("易助粒担保")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topBanner"},[_c('div',[_c('div',{staticClass:"title-box"},[_c('img',{staticClass:"jcdImg",attrs:{"src":require("@/assets/new/logo.jpg"),"alt":""}}),_c('div',{staticClass:"title-text"},[_vm._v("易助粒担保致力于为小微型企业、个体商户等客户提供专业的融资担保服务。")])]),_c('div',{staticClass:"top-sub-title"},[_vm._v("最高可担保额度（元）")]),_c('div',{staticClass:"top-money"},[_c('div',{staticClass:"top-money-num"},[_vm._v("¥200,000")]),_c('div',{staticClass:"top-money-bg"})])]),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-btm"},[_c('div',{staticClass:"top-btm-item"},[_c('img',{attrs:{"src":require("../../assets/new/1.png"),"alt":""}}),_c('div',{staticClass:"top-btm-item-rt"},[_c('p',[_vm._v("额度高")]),_c('p',[_vm._v("最高20万")])])]),_c('div',{staticClass:"top-btm-item top-btm-item2"},[_c('img',{attrs:{"src":require("../../assets/new/2.png"),"alt":""}}),_c('div',{staticClass:"top-btm-item-rt"},[_c('p',[_vm._v("期限灵活")]),_c('p',[_vm._v("6-60期选择多")])])]),_c('div',{staticClass:"top-btm-item"},[_c('img',{attrs:{"src":require("../../assets/new/3.png"),"alt":""}}),_c('div',{staticClass:"top-btm-item-rt"},[_c('p',[_vm._v("审批快")]),_c('p',[_vm._v("最快15分钟出额度")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apply"},[_c('div',{staticClass:"apply-title"},[_c('p',[_vm._v("申请流程")]),_c('img',{attrs:{"src":require("../../assets/new/title-bg.png"),"alt":""}}),_c('div',{staticClass:"apply-line"})]),_c('div',{staticClass:"apply-box"},[_c('div',{staticClass:"apply-item"},[_c('img',{staticClass:"apply-item-img",attrs:{"src":require("../../assets/new/4.png"),"alt":""}}),_c('div',{staticClass:"apply-item-text"},[_c('p',[_vm._v("线上申请")]),_c('p',[_vm._v("操作简单")]),_c('p',[_vm._v("申请流程在线完成")])]),_c('img',{staticClass:"apply-item-num",attrs:{"src":require("../../assets/new/41.png"),"alt":""}})]),_c('div',{staticClass:"apply-item"},[_c('img',{staticClass:"apply-item-img apply-item-img2",attrs:{"src":require("../../assets/new/5.png"),"alt":""}}),_c('div',{staticClass:"apply-item-text"},[_c('p',[_vm._v("线上申请")]),_c('p',[_vm._v("操作简单")]),_c('p',[_vm._v("申请流程在线完成")])]),_c('img',{staticClass:"apply-item-num",attrs:{"src":require("../../assets/new/51.png"),"alt":""}})]),_c('div',{staticClass:"apply-item"},[_c('img',{staticClass:"apply-item-img apply-item-img3",attrs:{"src":require("../../assets/new/6.png"),"alt":""}}),_c('div',{staticClass:"apply-item-text"},[_c('p',[_vm._v("线上申请")]),_c('p',[_vm._v("操作简单")]),_c('p',[_vm._v("申请流程在线完成")])]),_c('img',{staticClass:"apply-item-num",attrs:{"src":require("../../assets/new/61.png"),"alt":""}})]),_c('div',{staticClass:"apply-item"},[_c('img',{staticClass:"apply-item-img apply-item-img4",attrs:{"src":require("../../assets/new/7.png"),"alt":""}}),_c('div',{staticClass:"apply-item-text"},[_c('p',[_vm._v("线上申请")]),_c('p',[_vm._v("操作简单")]),_c('p',[_vm._v("申请流程在线完成")])]),_c('img',{staticClass:"apply-item-num",attrs:{"src":require("../../assets/new/71.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-title"},[_c('p',[_vm._v("关于易助粒担保")]),_c('img',{attrs:{"src":require("../../assets/new/title-bg.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company"},[_c('div',{staticClass:"company-box"},[_c('div',{staticClass:"company-lt"},[_c('div',{staticClass:"company-img"}),_c('p',{staticClass:"company-p1"},[_vm._v("公司名称：")]),_c('p',{staticClass:"company-p2"},[_vm._v("内蒙古禄盛通融资担保有限责任公司")])]),_c('div',{staticClass:"company-lt company-rt"},[_c('div',{staticClass:"company-img"}),_c('p',{staticClass:"company-p1"},[_vm._v("联系电话：")]),_c('p',{staticClass:"company-p2"},[_vm._v("17616333737")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"address"},[_c('div',{staticClass:"addressName"},[_vm._v("ICP备案：蒙ICP备2024019291")])])
}]

export { render, staticRenderFns }